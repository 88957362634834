import Box from '@material-ui/core/Box'
import {Collapse, Grid} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { createMuiTheme, lighten, makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import shareNeeds from '../../assets/images/shareNeeds.svg'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import moment from 'moment'
import PropTypes from 'prop-types'
import CommonLoader from '../CommonLoader'
import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import Button from '@material-ui/core/Button'
import './MyNeeds.css'
import MuiTableCell from "@material-ui/core/TableCell";
import axios from 'axios'

function createData(no, date_of_service, provider, charged, paid, status) {
  return { no, date_of_service, provider, charged, paid, status }
}


const theme = createMuiTheme({
  MuiTableCell: {
    paddingLeft: '30px',
   
  }
})

const rows = [
  createData('600016', '2020-04-20', 'VITALCARE FP LLC', 670, 43, 'FINAL'),
  createData('600017', '2020-05-20', 'VITALCARE FP LLC', 500, 100, 'IN REVIEW'),
  createData('600016', '2020-04-20', 'VITALCARE FP LLC', 670, 43, 'FINAL'),
  createData('600017', '2020-05-20', 'VITALCARE FP LLC', 500, 100, 'IN REVIEW'),
  createData('600016', '2020-04-20', 'VITALCARE FP LLC', 670, 43, 'FINAL'),
  createData('600017', '2020-05-20', 'VITALCARE FP LLC', 500, 100, 'IN REVIEW'),
  createData('600016', '2020-04-20', 'VITALCARE FP LLC', 670, 43, 'FINAL'),
  createData('600017', '2020-05-20', 'VITALCARE FP LLC', 500, 100, 'IN REVIEW'),
  createData('600016', '2020-04-20', 'VITALCARE FP LLC', 670, 43, 'FINAL'),
  createData('600017', '2020-05-20', 'VITALCARE FP LLC', 500, 100, 'IN REVIEW')
                      ]

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const headCells = [
          
  { id: 'arrow', numeric: true, disablePadding: false, label: '' },
  { id: 'bill_key', numeric: true, disablePadding: false, label: 'Reference #' },
  { id: 'service_date', numeric: true, disablePadding: false, label: 'Date Of Service' },
  { id: 'first_name', numeric: false, disablePadding: false, label: 'Member' },

  { id: 'paid_provider_name', numeric: false, disablePadding: false, label: 'Provider' },
  { id: 'charged_amount', numeric: true, disablePadding: false, label: 'Charged Amount' },
  { id: 'paid_amount', numeric: true, disablePadding: false, label: 'Paid Amount' },
        { id: 'status', numeric: true, disablePadding: false, label: ' Status' }
]

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow className="tableHeadRow">
        {/* <TableCell padding="checkbox">
         <Checkbox
           indeterminate={numSelected > 0 && numSelected < rowCount}
           checked={rowCount > 0 && numSelected === rowCount}
           onChange={onSelectAllClick}
           inputProps={{ 'aria-label': 'select all desserts' }}
         />
       </TableCell> */}
        {headCells.map(headCell => (
          <>
            {headCell.id == 'service_date' ? (
              <TableCell
                key={headCell.id}
                                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                className="sortheadercellColor tableHeader"
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  className="headercellColor tableHeader"
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell
                key={headCell.id}
                                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                className="headercellColor tableHeader"
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  className="headercellColor tableHeader"
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )}
          </>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: '1 1 100%'
  },
}))





const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  
}))

export default function 
DataTable(props) {
  const classes = useStyles()
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('service_date')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [dense, setDense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const { tableData } = props
  useEffect(() => {})

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }
  

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeDense = event => {
    setDense(event.target.checked)
  }

  const isSelected = name => selected.indexOf(name) !== -1

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  return (
    <div className={classes.root}>
      <Paper className={'tableContainer ' + classes.paper}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        {/* <div className="tablefixheight"> */}
        <TableContainer style={{height: window.mobileAndTabletCheck()?"500px":"300px"}}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
            stickyHeader

          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />

            <TableBody>
              {stableSort(tableData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((tableData, index) => {
                  const isItemSelected = isSelected(tableData.name)
                  const labelId = `enhanced-table-checkbox-${index}`

                  return <Row open={props.open} key={tableData.name} row={tableData} />
                })}
              {/* {emptyRows > 0 && (
               <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                 <TableCell colSpan={6} />
               </TableRow>
             )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={{ position: 'relative' }}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}

function Row(props) {
  const { row } = props
  const [open, setOpen] = React.useState(props.open)
  const [openpopup, setOpenpopup] = React.useState(false)
  const classes = useRowStyles()
  const [message, setMessage] = React.useState("")
  const [loader, setLoader] = React.useState(false)
  const [modalShow, setModalShow] = React.useState(false)
  const [contributors, setcontributors] = React.useState(null)
  const getDate = date => {
    const isDateValid = moment(date)['_isValid']
    if (isDateValid) {
      return moment(date).format('MMMM D, YYYY')
    }
    return date
  }

  const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const floatconversion = x => {
    return x.toFixed(2)
  }

    const combineDate = (x, y) => {
    if (x == y) {
      return x
    }

    const z = x + '-' + y
    return z
  }

  const convertToString = x => {
    console.log('Type Of X')
        console.log(x)
      }
     const transformString = (inputString, needType)=> {
      // Split the string into two parts: main part and the trailing part
      const mainPart = inputString.slice(0, -4); // All characters except the last 4
      const trailingPart = inputString.slice(-4); // Last 4 characters
    
      // Get the delimiter based on the NeedType
      const addString = needType === 'HCFA' ? '_P' : needType === 'UB' ? '_I' : '';
    
      // Construct the final string
      return `${mainPart}-${trailingPart[0]}${addString}`;
    }
  const openUrl=(id,NeedType)=>{
    let request = {
      username: process.env.REACT_APP_TOKEN_USERNAME,
      password: process.env.REACT_APP_TOKEN_PASSWORD
    }
    setLoader(true);
    axios.post(process.env.REACT_APP_TOKEN_API, {}, {
      auth: request,
      headers: { "Content-Type":"application/x-www-form-urlencoded"}
     }).then((response) => {
      let string = process.env.REACT_APP_EOS_API
      let modified_id = transformString(id,NeedType);
      string = string.replace("id", modified_id);
      axios.get(string, {
        headers: {
          'x-api-key': process.env.REACT_APP_X_API_KEY,
          token: response.data.access_token
        }
      }).then(response => {
        if(response.data[0]){
          window.open(Object.keys(response.data[0]).map(k => response.data[0][k])[0])
          setLoader(false);
        }else{
          setModalShow(true);
          setMessage("No EOS data available. Please try again later.");
          setLoader(false);
        }
      })
      
    })
  }
  const setOpenpopups =(billkey) => {
    let request = {
      username: process.env.REACT_APP_TOKEN_USERNAME,
      password: process.env.REACT_APP_TOKEN_PASSWORD
    }
    setLoader(true);
    axios.post(process.env.REACT_APP_TOKEN_API, {}, {
      auth: request,
      headers: { "Content-Type":"application/x-www-form-urlencoded"}
     }).then((response) => {
      axios.get(`${process.env.REACT_APP_CONTRIBUTORS_API}${billkey}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_X_API_KEY,
          token: response.data.access_token
        }
      }).then(response => {
        if(response.data){
          if(response.data.contributors.length>0){
            setOpenpopup(true)
            setLoader(false);
            setcontributors(response.data)
          }else{
            setModalShow(true);
            setMessage("No contribution data available. Please try again later.");
            setLoader(false);
          }
        }else{
          setOpenpopup(false);
          setLoader(false);
        }
      })
      
    })
  




  }           
  return (
    <React.Fragment>
       {loader ? <CommonLoader /> : null}
      <ThemeProvider theme={theme}>
        <TableRow className={'rowTable ' + classes.root}>
          <TableCell className="rowTableCell" >
            <IconButton className="tableExpandIcon" aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row" className="rowcellcolor rowTableCell">
            {row.bill_key}
          </TableCell>
          <TableCell align="left" className="sortrowcellcolor rowTableCell">
            {getDate(row.service_date)}
          </TableCell>
          <TableCell component="th" scope="row" className="rowcellcolor rowTableCell">
            {row.first_name} {row.last_name}
          </TableCell>
         
          <TableCell align="left" className="rowcellcolor rowTableCell">
            {row.paid_provider_name}
          </TableCell>
          <TableCell align="left" className="rowcellcolor rowTableCell">
            ${row.charged_amount}
          </TableCell>
          <TableCell align="left" className="rowcellcolor rowTableCell">
            {row.paid_amount?"$"+row.paid_amount:"NA"}
          </TableCell>
          <TableCell align="left" className="rowTableCell">
            {(row.status == 'Paid' || row.status == 'Denied' || row.status == 'Issued')? (
              <span className={'web_status_button Audit'}>FINAL</span>
            ) : (
              <span className={'web_status_button Logged'}>IN REVIEW</span>
            )}
          </TableCell>
        </TableRow>

        {/* The Following table row is used to for the information of the dropdown rows that explain the EOS */}
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" component="tr" unmountOnExit style={{ backgroundColor: '#f6f6f6' }}>
              <Box>
                {/* <Table className="collapseTableContainer" size="small" aria-label="purchases">
               <TableHead> */}
                <TableRow style={{borderBottom:"none"}}>
                  <TableCell component="td" align="left" className="innerTh blankCell" style={{ width: '5%' }}>
                    &nbsp;
                  </TableCell>
                  <TableCell align="left" className="innerTh" style={{ width: '230px' }}>
                  Paid Date
                  </TableCell>
                  {/* <TableCell align="left" className="innerTh" style={{ width: '404px' }}>
                    Provider{' '}
                  </TableCell> */}
                  <TableCell align="left" className="innerTh" style={{ width: '230px' }}>
                  Repriced Amount
                  </TableCell>
                  <TableCell align="left" className="innerTh" style={{ width: '230px' }}>
                  Visit Fees (MPV)
                  </TableCell>

                  <TableCell align="left" className="innerTh" style={{ width: '240px' }}>
                  Member Commitment Portion (MCP)
                  </TableCell>

                  {/* <TableCell align="left" className="innerTh" style={{ width: '341px' }}>
                    Eligible for Sharing
                  </TableCell> */}

                  {/* <TableCell align="left" className="innerTh" style={{ width: '250px' }}>
                    Non-Shareable Amount
                  </TableCell> */}

                  <TableCell align="left" className="innerTh" style={{ width: '230px' }}>
                  Shareable Amount
                  </TableCell>

                  <TableCell align="left" className="innerTh" style={{ width: '150px' }}>
                  Explanation of Sharing (EOS)
                  </TableCell>

                  <TableCell align="left" className="innerTh" style={{ width: '162px' }}>
                  Your Community Sharing
                  </TableCell>
                </TableRow>
                {/* </TableHead> */}

                <TableBody>
                  {/* {row.inlineData.map((item) => ( */}
                  <TableRow>
                    <TableCell className="innerTh" align="left">
                      &nbsp;
                    </TableCell>
                    <TableCell align="left" className="innerTh">
                      {getDate(row.date_paid)}
                    </TableCell>
                    {/* <TableCell component="th" scope="row" className="innerTh" align="left">
                      {
                                                row.paid_provider_name
                      }
                    </TableCell> */}

                    <TableCell align="left" className="innerTh">
                      {<>${row.repricing_amount}</>}
                    </TableCell>

                    <TableCell align="left" className="innerTh">
                      {<>${row.mpv}</>}
                    </TableCell>

                    <TableCell align="left" className="innerTh">
                      {<>${row.mcp}</>}
                    </TableCell>

                    {/* <TableCell align="left" className="innerTh">
                      <>${row.eligible_for_sharing}</>
                    </TableCell> */}

                    {/* <TableCell align="left" className="innerTh">
                      {<>${row.nsa ? row.nsa : '0'}</>}
                    </TableCell> */}
                    <TableCell align="left" className="innerTh">
                      {<>${row.shareable_amount}</>}
                    </TableCell>
                    
                    <TableCell align="left" className="innerTh number-View-download" onClick={() =>openUrl(row.bill_key,row.claim_type)}>
                    VIEW/DOWNLOAD
                    </TableCell>
                    {/* <TableCell align="left" className="innerTh">
                      {<>Not Available</>}
                    </TableCell> */}
                    <TableCell align="left" className="innerTh number-View-download" onClick={() =>setOpenpopups(row.bill_key)}>
                      {<>VIEW</>}
                    </TableCell>

                    {/* <TableCell align="left" className="innerTh">
                                    {row.refundAmount}
                                 </TableCell> */}
                    {/* <TableCell align="left" className="innerTh">
                     {row.refundDescription}
                   </TableCell> */}
                  </TableRow>
                  {/* ))} */}
                  </TableBody>

                  {/* <TableBody colSpan={12}>
                   <TableRow style={{backgroundColor:'#EEEEEE'}}>
                    <TableCell padding='1px' align="left" className="innerTh shareNeeds">
                    <div style={{ width:27, paddingLeft: 6}}>
                      <img src={shareNeeds} width={50}/>
                    </div>
                    </TableCell>
                    <TableCell padding='1px' align="left" className="innerTh shareNeeds" colSpan={2}>
                      <p style={{marginTop:16, fontWeight:"500", color: "#454d58"}}>
                      Shared by UHF Members <b style={{  fontWeight:"bold", color: "#00000", fontSize:"16px",}}> ${row.eligible_for_sharing} </b> 
                      </p> 
                    </TableCell>
                    
                    <TableCell padding='1px' align="left" className="innerTh shareNeeds" colSpan={2}>
                      <p style={{width:"auto", marginTop:16, fontWeight:"500", color: "#454d58"}}>
                      No. of Contributing Members  <b style={{  fontWeight:"bold", color: "#00000", fontSize:"16px",}}> 12 </b> 
                      
                      </p> 
                    </TableCell>
                    <TableCell padding='1px' align="left" className="innerTh shareNeeds" colSpan={2}>
                    <button className="ButtonBGNeeds">
                      <p style={{marginTop:5}} className="captionNeeds">SEND THANK YOU NOTE</p>
                      </button>
                    </TableCell>
                    <TableCell padding='1px' align="left" className="innerTh shareNeeds">
                      
                    </TableCell>
                    <TableCell padding='1px' align="left" className="innerTh shareNeeds" colSpan={2}>
                      
                    </TableCell>
                    <TableCell padding='1px' align="left" className="innerTh shareNeeds">
                   
                    </TableCell>
                    <TableCell padding='1px' align="left" className="innerTh shareNeeds">
                   
                    </TableCell>
                    <TableCell padding='1px' align="left" className="innerTh shareNeeds">
                   
                    </TableCell>
                    
                    </TableRow>
                  </TableBody> */}


         
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
        <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} data={row} message={message} />
      </ThemeProvider>
      <Modal dialogClassName="my-modal" show={openpopup} onHide={()=>setOpenpopup(false)} centered className="ChangPayModal" backdrop="static">
          <Modal.Header style={{height:56, fontFamily:"Lato"}}>
          <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <h6 style={{color:"#373a3c"}}>Your Community Sharing</h6>
                <IconButton aria-label="close" style={{ marginTop: '-13px', marginRight:"-15px" }} onClick={() =>{setOpenpopup(false); setcontributors(null)}}>
                  <CloseIcon />
                </IconButton>
              </Grid>
          </Modal.Header>
          <Modal.Body>
            <div style={{padding:15}}> 
            <TableContainer  component={Paper}>
              <Table  sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow style={{borderBlockColor:"black"}}>
                    <TableCell style={{borderColor:"#8c827a"}}><p style={{width:"auto", marginTop:16, fontWeight:"normal", color: "#454d58", paddingLeft:10}}>Member</p></TableCell>
                    <TableCell align="right" style={{borderColor:"#8c827a"}}><p style={{width:"auto", marginTop:16,fontWeight:"normal", color: "#454d58", paddingRight:10}}>Contribution Amount</p></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{maxHeight:173, overflowY: 'auto', display:'block', width:"214%"}}>
                
                  {contributors && contributors.contributors && contributors.contributors.map((res, i)=> <TableRow key={i}>
                      <TableCell align="left" style={{borderColor:contributors.contributors.length==i+1?"#8c827a":"", width:"123%"}}><p style={{width:"auto", marginTop:16, fontWeight:"500", color:"black", paddingLeft:10}}>{res.first_name[0]} {res.last_name[0]}</p></TableCell>
                      <TableCell align="right" style={{borderColor:contributors.contributors.length==i+1?"#8c827a":"", width:"123%"}}><p style={{width:"auto", marginTop:16, fontWeight:"500", color:"black", paddingRight:10}}>{res.contribution_amount}</p></TableCell>
                    </TableRow>)}
                  
                </TableBody>
                {contributors && 
                  <TableRow style={{backgroundColor:"#F6F6F6"}} key={"total"}>
                      <TableCell align="left"><p style={{width:"auto", marginTop:16, fontWeight:"500", color:"black", paddingLeft:10}}>Total Contribution</p></TableCell>
                      <TableCell align="right"><p style={{width:"auto", marginTop:16, fontWeight:"500", color:"black", paddingRight:10}}>{contributors.total_contributions}</p></TableCell>
                    </TableRow>}  
              </Table>
            </TableContainer>

            </div>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" style={{ marginRight: '15px' }} 
                onClick={() => {setOpenpopup(false); setcontributors(null)}}
                class="reimbursement-ButtonBGsave">
                  CLOSE
                </Button>
          </Modal.Footer>
          </Modal>
    </React.Fragment>
  )
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
})

const myrowsjsondata = [
  {
    member_id: '600016',
    bill_key: '20116',
    first_name: 'CHARLES',
    last_name: 'CASH',
    date_paid: 'None',
    date_received: '2020-04-20',
    status: 'IN REVIEW',
    charged_amount: '0.00',
    paid_amount: '0.00',
    paid_provider_name: 'aa'
  },
  {
    member_id: '600017',
    bill_key: '2046',
    first_name: 'CHARLES',
    last_name: 'CASH2',
    date_paid: '2020-05-20',
    date_received: '2020-04-21',
    status: 'FINAL',
    charged_amount: '100.00',
    paid_amount: '10.00',
    paid_provider_name: 'bb'
  },
  {
    member_id: '600018',
    bill_key: '20118',
    first_name: 'CHARLES',
    last_name: 'CASH',
    date_paid: 'None',
    date_received: '2020-04-22',
    status: 'FINAL',
    charged_amount: '200.00',
    paid_amount: '20.00',
    paid_provider_name: 'cc'
  },
  {
    member_id: '600019',
    bill_key: '2053',
    first_name: 'CHARLES',
    last_name: 'CASH2',
    date_paid: '2020-05-20',
    date_received: '2020-04-23',
    status: 'FINAL',
    charged_amount: '300.00',
    paid_amount: '30.00',
    paid_provider_name: 'VITALCARE FP LLC'
  },
  {
    member_id: '600018',
    bill_key: '201337',
    first_name: 'CHARLES',
    last_name: 'CASH',
    date_paid: 'None',
    date_received: '2020-04-24',
    status: 'IN REVIEW',
    charged_amount: '400.00',
    paid_amount: '40.00',
    paid_provider_name: 'VITALCARE FP LLC'
  },
  {
    member_id: '600019',
    bill_key: '201857',
    first_name: 'CHARLES',
    last_name: 'CASH2',
    date_paid: '2020-05-20',
    date_received: '2020-04-25',
    status: 'IN REVIEW',
    charged_amount: '0.00',
    paid_amount: '0.00',
    paid_provider_name: 'VITALCARE FP LLC'
  }
]

function MyVerticallyCenteredModal(props) {
  const { data } = props
  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton style={{ height: 56, backgroundColor: '#33afb0', borderBottom: '1px solid #999999' }}>
        <Modal.Title id="contained-modal-title-vcenter"><h4
         style={{
          fontWeight: '500',
          fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
          fontSize: '1rem'
        }}
        >Message</h4></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6  style={{
                    fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
                    fontSize: '16px'
                  }}>{props.message}</h6>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}  style={{
                  backgroundColor: '#4782c4',
                  color: '#ffffff',
                  display: 'inline-flex',
                  alignItems: 'center',
                  fontFamily: 'Roboto, Arial, Helvetica, sans-serif'
                }} >Close</Button>
      </Modal.Footer>
    </Modal>
  )
}
