import Fab from '@material-ui/core/Fab'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ForumIcon from '@material-ui/icons/Forum'
import { withStyles } from '@material-ui/styles'
import React, { Component } from 'react'
import { getEMPI, getMyneedsEOS, getReimbursementByMember } from '../ApiCall'
import CommonLoader from '../CommonLoader'
import CommonFooter from './CommonFooter'
import MobileFooter from '../..../../MobileScreen/MobileFooter'
import DataTable from './DataTable'
import Header from './Header'
import './MyNeeds.css'
import customStyle from './programInfo/CSS/stylesheet_UHS.js'
import styleJson from '../styleJSON'
import { Button } from 'react-bootstrap'
import { Box, Grid, Hidden, Tab, Tabs } from '@material-ui/core'
import ReimbursementLeftSide from './ReimbursementRequest/ReimbursementLeftSide/ReimbursementLeftSide'
import ReimbursementModal1 from './ReimbursementRequest/ReimbursementModal.js/ReimbursementModall1'
import ReimbursementModal2 from './ReimbursementRequest/ReimbursementModal.js/ReimbursementModal2/ReimbursementModal2'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import Index from './ReimbursementContainer/Index'
import ReimbursementData from './ReimbursementRequest/ReimbursementData/ReimbursementData'
const AntTabs = withStyles(customStyle.MyneedsTabs)(Tabs)
const CrudButton = withStyles(customStyle.crudBtn)(Fab)
const CrudButtonNetwell = withStyles(customStyle.crudBtnNetwell)(Fab)

window.mobileAndTabletCheck = function() {
  let check = false
  ;(function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

export default class MyNeeds extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      isReimbursementFlow: false,
      tableData: [],
      loader: true,
      notfoundmsg1: '',
      notfoundmsg2: '',
      notfoundmsg3: '',
      open: false,
      memberid:null,
      Reimbursement:null,
      referenceId:null,
      selectedTab:"MyShareRequests",
      netWellUser: process.env.REACT_APP_NETWELLUSER == 'true' ? true : false,
      styles: process.env.REACT_APP_NETWELLUSER == 'true' ? styleJson.NetWell : styleJson.UHF
    }
   
  }
 
  componentDidMount() {
    this.getEMPID()
    let urlValues = new URLSearchParams(window.location.search)
    let a = urlValues.get('open')
    if (a == true || a == 'true')
      this.setState({ open: true }, () => {
        this.props.history.push('/MyNeeds')
      })
      this.getReimbursementByMember();
  }
  getReimbursementByMember=() => {
    getReimbursementByMember().then(response=>{
      this.setState({Reimbursement: response})
      console.log('Reimbursement', response)
    })
  }
  getEMPID() {
    // getEMPI().then(res => {
      this.myneedsEOS()
    // })
  }
 
 

  myneedsEOS = () => {
    getMyneedsEOS().then(res => {
      if (res.data && res.data.length > 0) {
        this.setState({ tableData: res.data, loader: false })
      } else {
        this.setState({
          notfoundmsg1: 'No share requests have been entered into the system.',
          notfoundmsg2: 'Submitted needs may take 2-3 days to be displayed here.',
          notfoundmsg3: 'For any clarifications call Customer Service.',
          loader: false
        })
      }
    })
  }
  openReimbursementRequest=()=>{
    this.setState((state)=>({...state, isReimbursementFlow: true }))
  }
  updateReimbursementRequest=(id, referenceId)=>{
    this.setState((state)=>({...state, isReimbursementFlow: true, memberid:id, referenceId: referenceId}))
  }
  render() {
   if(this.state.isReimbursementFlow) return <Index referenceId={this.state.referenceId} memberid={this.state.memberid} backClick={()=>this.setState({isReimbursementFlow:false, memberid:null, referenceId:null, selectedTab:"MyreimbursementRequests"},()=>{this.getReimbursementByMember(); window.scrollTo(0, 0)})}/>

    return (
      
      <div className="progInfoMainWrapper">
        <Header name={'Sharing Requests'} />
        {this.state.loader ? <CommonLoader /> : null}
        <div className="container progInfoContainer" style={{ zIndex: '0' }}>
          <sapn className={this.state.styles.backpage} onClick={() => this.props.history.push("/")}>
            <ArrowBackIcon style={{ width: '24px', height: '24px', color: '#4782c4', marginRight: '5px' }} onClick={this.goBack} />
            BACK
          </sapn>

          <div className="commonWrap_MyNeeds" style={{ marginTop: '15px' }}>
            {/* <div className="progInfoSection" style={{ marginBottom: '-20px' }}>
              <h2 className="progInfoTitle" style={{ color: this.state.netWellUser ? '#162242' : '#162242',fontSize:"30px" }}>
               MY SHARE REQUESTS
              </h2>
            </div> */}
            <div>
            <AntTabs
                value={this.state.selectedTab}
                onChange={(e, newValue) => this.setState({selectedTab: newValue})}
                textColor="#33333"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
                variant="fullWidth"
                id="tabs"
                style={{ width: '100%' }}
              >
                <Tab label="MY SHARE REQUESTS" value="MyShareRequests" style={{ textTransform: 'capitalize',width:'13%',marginTop:10,fontSize:24, color: this.state.selectedTab == 'MyShareRequests' ? '#000' : '#7e7e7e'}} />
                <Tab label="MY REIMBURSEMENT REQUESTS" value="MyreimbursementRequests" style={{ textTransform: 'capitalize',width:'20%',marginTop:10,fontSize:24, color: this.state.selectedTab == 'MyreimbursementRequests' ? '#000' : '#7e7e7e' }} />
              </AntTabs>

              
 
              <div className="tablebackgroundouter" style={{height:window.mobileAndTabletCheck()? '550px' : ""}}>
              {this.state.selectedTab === 'MyShareRequests' && 
              <div>
                {/* {false ? <> */}
                {this.state.tableData && this.state.tableData.length > 0 ? (
                  <DataTable open={this.state.open} tableData={this.state.tableData} />
                ) : (
                  <>
                    <div className="data_not_found">
                      <h5 class="noneeds_msg_display">{this.state.notfoundmsg1}</h5>
                      <h5 class="noneeds_msg_display">{this.state.notfoundmsg2}</h5>
                      <h5 class="noneeds_msg_display">{this.state.notfoundmsg3}</h5>
                    </div>
                  </>
                )}
                {/* </>:
                <div className="data_not_found">
                <h5 class="noneeds_msg_display">No sharing request information available.</h5>
                </div>} */}
                </div>}
                {/* <h2 className="progInfoTitle" style={{ color: this.state.netWellUser ? '#162242' : '#162242',fontSize:"30px" }}>
                MY REIMBURSEMENT REQUESTS
              </h2> */}
              {this.state.selectedTab === 'MyreimbursementRequests' &&  <div>
                {this.state.Reimbursement && this.state.Reimbursement.length > 0 ? (
                  <ReimbursementData update={(id, referenceId)=>this.updateReimbursementRequest(id, referenceId)} open={this.state.open} tableData={this.state.Reimbursement} />
                ) : (
                  <>
                    <div className="data_not_found">
                      <h5 class="noneeds_msg_display">
                      You haven't submitted any reimbursement requests yet. Click the button below to submit one.
                      </h5>
                    
                    </div>
                  </>
                )}
              </div>}
              </div>
             
              <div className="progInfoFooter">
                <div className="row">
                  <div className="col-md-7" style={{width:window.mobileAndTabletCheck()?'30%': '100%'}}>
                    {' '}
                    <Button className="rButton"  style={{width:window.mobileAndTabletCheck()?'100px': '200px',fontSize:window.mobileAndTabletCheck()?'1.8vw': '1.1vw'}}  onClick={this.openReimbursementRequest}>REIMBURSEMENT <br></br> REQUEST</Button>
                  </div>
                  <div className="col-md-4 dpFlex" style={{width:window.mobileAndTabletCheck()?'63%': '100%',margin:"-1px  0px 10px 25px"}}>
                    <div className="footerText" style={{ display: 'flex', flexDirection: 'row' }}>
                      <div>
                        <p>Need help?</p>
                        <p>Call our netWell Representative</p>
                        <p>on (866) NETWELL (638-9355).</p>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
           
        </div>
        <div style={{ paddingTop: 45 }}>
          <CommonFooter />
        </div>
      </div>
    )
  }

  openChat = () => {
    sessionStorage.setItem('chatwindow', true)
    this.props.history.push('/')
  }
}
const arrayNeeds = [
  {
    member_id: 'CNEO10421340',
    empi: '1015',
    bill_key: '20766',
    first_name: 'Elaine',
    last_name: 'Williams',
    date_paid: '2020-12-03',
    date_received: '2020-07-02',
    status: 'Paid',
    charged_amount: 527.0,
    paid_amount: 451.37,
    paid_provider_name: 'HealthWay Clinic.',
    start_of_service_date: '2020-03-10',
    end_date_of_service: '2020-03-10',
    nsa: 0.0,
    sharing_percentage: 0.0,
    consultation_fee: 25.0,
    ineligible_amount: 15.7,
    repricing_amount: 34.93,
    eligible_for_sharing: 451.37,
    member_responsibility: 40.7
  }
]
