import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import React, { Component } from 'react'
import { getCardEnableData, getEMPI, getMyneedsEOS,getReimbursementByMember } from '../../ApiCall'
import CommonLoader from '../../CommonLoader'
import ChatIcon from '../../WebScreen/ChatBox/ChatIcon'
import MobCopyright from '../MobCopyright'
import MyNeedsSimpleAccordion from '../MobileCommonComponents/myneedsAccordion'
import ReimbursementDataMobile from './ReimbursementDataMobile'
import MobileHeader from '../MobileHeader'
import MobileFooter from './../MobileFooter'
import { Box, Hidden, Tab, Tabs } from '@material-ui/core'
import customStyle from '../../WebScreen/programInfo/CSS/stylesheet_UHS'
import { withStyles } from '@material-ui/styles'
import DataTable from '../../WebScreen/DataTable'
import ReimbursementData from '../../WebScreen/ReimbursementRequest/ReimbursementData/ReimbursementData'
import Index from '../../WebScreen/ReimbursementContainer/Index'
import { Button } from 'react-bootstrap'
const AntTabs = withStyles(customStyle.MyneedsTabs)(Tabs)
const CrudButton = withStyles(customStyle.crudBtn)(Fab)
const CrudButtonNetwell = withStyles(customStyle.crudBtnNetwell)(Fab)
export default class MyNeedsMobile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isReimbursementFlow: false,
      tableData: [],
      open: false,
      sampleData: sampleData,
      response: [],
      formatedjsonData: null,
      page: 0,
      loader: true,
      Reimbursement:null,
      selectedTab:"MyShareRequests",
      loadMoreBtnshow: false,
      notfoundmsg1: '',
      notfoundmsg2: '',
      notfoundmsg3: '',
      referenceId: null,
      memberid:null,
    }
  }

  componentDidMount() {
    this.getCardEnable()
    this.getEMPID()
    this.callapi()

    let urlValues = new URLSearchParams(window.location.search)
    let a = urlValues.get('open')
    if (a == true || a == 'true')
      this.setState({ open: true }, () => {
        this.props.history.push('/MyNeeds')
      })
      this.getReimbursementByMember();

  }

  getReimbursementByMember=() => {
    getReimbursementByMember().then(response=>{
      this.setState({Reimbursement: response})
      console.log('Reimbursement', response)
    })
  }

  getEMPID() {
    // getEMPI().then(res => {
      this.myneedsEOS()
    // })
  }
 

  myneedsEOS = () => {
    getMyneedsEOS().then(res => {
      console.log('response',res)
      if (res.data && res.data.length > 0) {
        this.setState({ tableData: res.data, loader: false })
      } else {
        this.setState({
          notfoundmsg1: 'No share requests have been entered into the system.',
          notfoundmsg2: 'Submitted needs may take 2-3 days to be displayed here.',
          notfoundmsg3: 'For any clarifications call Customer Service.',
          loader: false
        })
      }
    })
  }
  openReimbursementRequest=()=>{
    this.setState((state)=>({...state, isReimbursementFlow: true }))
  }
  updateReimbursementRequest=(id, referenceId)=>{
    this.setState((state)=>({...state, isReimbursementFlow: true, memberid:id, referenceId: referenceId}))
  }

  callapi() {
    getMyneedsEOS(this.state.page).then(res => {
      console.log('==============MY needs data mobile===========', res.data)
      if (res.data.length > 0) {
        this.setState({ response: res.data, loader: false })
      } else {
        this.setState({
          notfoundmsg:
            'No Share Requests have been entered into the system. Submitted needs may take 2-3 days to be displayed here. For any clarifications call Customer Service.',
          loader: false
        })
      }

      if (res.data.length > 10) {
        this.setState({ loadMoreBtnshow: true })
      }
      this.setState({ response: res.data, page: this.state.page + 1, loader: false })
      this.createjson()
    })
  }

  callapiLoadmore() {
    this.setState({ loader: true })
    getMyneedsEOS(this.state.page).then(res => {
      console.log('==============TRansaction data mobile===========', res.data)
      console.log('my loade more res===', res)
      var resnew = res.data
      var data = this.state.response
      var newdata = data.concat(resnew)

      console.log('new data loade more is==', newdata)

      if (res.data.totalrecords === newdata.length) {
        this.setState({ loadMoreBtnshow: false })
      }
      this.setState({ response: newdata, loader: false, page: this.state.page + 1 })
      this.createjson()
    })
  }

  getCardEnable = () => {
    let client_id = localStorage.getItem('CLIENT_ID')

    getCardEnableData(client_id, 'MyNeeds').then(res => {
      console.log('getCardEnableData=====', res.data.response.enable)

      if (res.data.response.enable == 'false' || res.data.response.enable == false) {
        window.location.href = '/'
      }
    })
  }

  combineDate = (x, y) => {
    if (x == y) {
      return x
    }

    const z = x + '-' + y
    return z
  }
  createjson() {
    var formatredjson = []

    this.state.response.map((item, i) => {
      var newobject = {}
      newobject.ExpenseNumberLabel = 'Reference #'
      newobject.ExpenseNumberVal = item.bill_key

      newobject.DateOfServiceLabel = 'Date of Service'
      newobject.DateOfServiceVal = item.service_date

      newobject.statusClass = item.status
      newobject.status = item.status

      newobject.ProviderLabel = 'Provider'
      newobject.ProviderVal = item.paid_provider_name

      newobject.ChargedLabel = 'Charged Amount'
      newobject.ChargedVal = item.charged_amount

      newobject.PaidLabel = 'Paid Amount'
      newobject.PaidVal = item.paid_amount

      newobject.row7 = 'Member'
      newobject.rowval7 = item.first_name + ' ' + item.last_name

      newobject.row8 = 'Paid Date'
      newobject.rowval8 = item.date_received

      var innerobject = {
        DateofServiceLabel: 'Date(s) of Service',
        DateofServiceVal: this.combineDate(item.service_date),

        ProviderNameLabel: 'Provider',
        ProviderNameVal: item.paid_provider_name,

        BillChargeLabel: 'Billed Charges',
        BillChargeVal: '$' + item.charged_amount,

        IneligibleforSharingLabel: 'Ineligible for Sharing',
        IneligibleforSharingVal: '$' + item.ineligible_amount,

        DiscountToBilledChargesLabel: 'Discount to Billed Charges',
        DiscountToBilledChargesVal: '$' + item.repricing_amount,

        EligibleforSharingLabel: 'Eligible for Sharing',
        EligibleforSharingVal: '$' + item.eligible_for_sharing,

        NonShareableAmountLabel: 'Non-Shareable Amount',
        NonShareableAmountVal: '$' + item.nsa,

        ConsultationFeeLabel: 'Consultation Fee',
        ConsultationFeeVal: '$' + item.consultation_fee,

        MemberResponsibilityLabel: 'Member Responsibility',
        MemberResponsibilityVal: '$' + item.member_responsibility,

        paidDateLabel: '	Paid Date',
        paidDateVal: item.date_paid,
        RepricedAmountLabel:"Repriced Amount",
        RepricedAmountVal: "$"+item.repricing_amount,
        MCPVLabel:`Visit Fees\n (MCP)`,
        MCVPValue: "$0.00",
        MCPLabel:"Member Commitment Portion (MCP)",
        MCPvalue:"$"+item.mcp,
        ShareableAmountLabel:"Shareable Amount",
        ShareableAmountValue:"$0.00",

        SharedByUHFLabel: 'Shared By UHF',
        SharedByUHFVal: '$' + item.paid_amount
      }

      newobject.MoreDetail = innerobject

      formatredjson = formatredjson.concat(newobject)
    })

    this.setState({ formatedjsonData: formatredjson })
  }

  render() {
    if(this.state.isReimbursementFlow) return <Index referenceId={this.state.referenceId} memberid={this.state.memberid} backClick={()=>this.setState({isReimbursementFlow:false, memberid:null, referenceId:null, selectedTab:"MyreimbursementRequests"},()=>{this.getReimbursementByMember(); window.scrollTo(0, 0)})}/>

    const { formatedjsonData } = this.state
    return (
      <div className="">
        {this.state.loader ? <CommonLoader /> : null}
        <MobileHeader name="Share Requests" />
        <AntTabs
                value={this.state.selectedTab}
                onChange={(e, newValue) => this.setState({selectedTab: newValue})}
                textColor="#33333"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
                variant="fullWidth"
                id="tabs"
                style={{ width: '100%',height:'auto',marginTop: 25}}
              >
                <Tab label="MY SHARE REQUESTS" value="MyShareRequests" style={{ textTransform: 'capitalize',width:'13%',marginTop:window.mobileAndTabletCheck()? "28px":"10px",fontSize:"4vw",color: this.state.selectedTab == 'MyShareRequests' ? '#000' : '#7e7e7e' }} />
                <Tab label="MY REIMBURSEMENT REQUEST" value="MyreimbursementRequests" style={{ textTransform: 'capitalize',width:'20%',margin:window.mobileAndTabletCheck()? "40px 0px 4px 0px":"10px 0px 0px 0px",fontSize:"4vw",color: this.state.selectedTab == 'MyreimbursementRequests' ? '#000' : '#7e7e7e'  }} />

              </AntTabs>
        {/* ------------------------------------------------ */}
        
          <div className="mobileCommonBodyR">
            <div>
            {this.state.selectedTab === 'MyShareRequests' && 
               <div>
                {/* {false ? <> */}
                {this.state.tableData && this.state.tableData.length > 0 ? (
                  <div>
                  <MyNeedsSimpleAccordion data={formatedjsonData} />
                  {this.state.loadMoreBtnshow ? (
                <div className="loadMoreWrapper">
                  <button className="loadMoreBtn" onClick={() => this.callapiLoadmore()}>
                    Load More
                  </button>
                </div>
              ) : null}
                  </div>
                ) : (
                  <>
                    <div className="data_not_found">
                      <h5 class="noneeds_msg_display" style={{fontSize:"15px"}}>{this.state.notfoundmsg1}</h5>
                      <h5 class="noneeds_msg_display" style={{fontSize:"15px"}}>{this.state.notfoundmsg2}</h5>
                      <h5 class="noneeds_msg_display" style={{fontSize:"15px"}}>{this.state.notfoundmsg3}</h5>
                    </div>
                  </>
                )}
                {/* </>:
                <div className="data_not_found">
                <h5 class="noneeds_msg_display">No sharing request information available.</h5>
                </div>} */}
                </div>}
                {/* <h2 className="progInfoTitle" style={{ color: this.state.netWellUser ? '#162242' : '#162242',fontSize:"30px" }}>
                MY REIMBURSEMENT REQUESTS
              </h2> */}
              {this.state.selectedTab === 'MyreimbursementRequests' &&  <div>
                {this.state.Reimbursement && this.state.Reimbursement.length > 0 ? (
                  <div style={{height:"80vh", overflowY:'auto', overflowX:"hidden"}}>
                  <ReimbursementDataMobile update={(id, referenceId)=>this.updateReimbursementRequest(id, referenceId)} open={this.state.open} tableData={this.state.Reimbursement} />
                </div>
                 ) : (
                  <>
                    <div className="data_not_found">
                      <h5 class="noneeds_msg_display" style={{fontSize:15}}>
                      You haven't submitted any reimbursement requests yet. Click the button below to submit one.
                      </h5>
                    
                    </div>
                  </>
                )}
              </div>}
            </div>

           
          </div>
          

        {/* {this.state.loader ? null : (
          <div style={{ bottom: '9vh', position: 'fixed', display: 'flex', flexDirection: 'column' }}>
            <ChatIcon showAIChatIcon={true} />
            <div >
              <ChatIcon />
            </div>
            <div style={{ bottom: '9vh', position: 'fixed' }}>
              <MobCopyright />
            </div>
          </div>
        )} */}
        {/* <MobileFooter /> */}
        {/* <div class="fixed-bottom">
          <MobileFooter name="My Needs" />
        </div> */}
        
        {this.state.loader ? null : (
          <div>
                
            <div style={{ bottom: '6vh', position: 'relative' }}>
            <div className="footerChatNeedsHelp" style={{ paddingTop: '0px', width: '100%' }}>

            <div className="footerText" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{marginTop:"-3px"}}><Button className="rButton"  style={{width:window.mobileAndTabletCheck()?'130px': '200px',fontSize:12,padding:"9px",fontWeight:500}}  onClick={this.openReimbursementRequest}>REIMBURSEMENT <br></br> REQUEST</Button></div>
                    <div>
                      <p>Need help?</p>
                      <p>Call our netWell Representative</p>
                      <p>on (866) NETWELL (638-9355).</p>
                    </div>
              <div style={{ marginLeft: '-30px' }} className="">
              {/* <CrudButtonNetwell
                      className={'purechat-button-expand'}
                      color="primary"
                      // onClick={() => window.pureChat()}
                      aria-label="add"
                      style={{
                        width: '56px',
                        height: '56px',
                        color: '#ffffff',
                        borderRadius: '50%',
                        border: "none",
                        backgroundColor:"#8DC73F"}} disabled={true}> 
                          <ForumIcon />
                    </CrudButtonNetwell> */}
              </div>
            </div>
          </div>
            <MobCopyright />
          </div>
         
          <div class="fixed-bottom">
            
            <MobileFooter name="Share Requests"/>
          </div>
      
        </div>)}
      </div>
    )
  }
}

const sampleData2 = [
  { id: 733, planId: '7006', idcardField: 'Telemedicine', fieldValue: '$0' },
  { id: 735, planId: '7006', idcardField: 'PCP Office Visit', fieldValue: '$35' },
  { id: 736, planId: '7006', idcardField: 'Urgent Care', fieldValue: '$100' },
  { id: 737, planId: '7006', idcardField: 'ER Visit', fieldValue: '$400' },
  { id: 737, planId: '7006', idcardField: 'ER Visit', fieldValue: '$400' },
  { id: 737, planId: '7006', idcardField: 'ER Visit', fieldValue: '$400' }
]
const sampleData = [
  {
    ExpenseNumberLabel: 'Expense Number',
    ExpenseNumberVal: '1248',
    DateOfServiceLabel: 'Date of Service',
    DateOfServiceVal: 'July 22, 2020',
    statusClass: 'InReview',
    status: 'In Review',
    ProviderLabel: 'Provider',
    ProviderVal: 'Healthway Clinic',
    ChargedLabel: 'Charged',
    ChargedVal: '$505.00',
    PaidLabel: 'Paid',
    PaidVal: 'NA',
    MoreDetail: {
      MemberLabel: 'Member',
      MemberVal: 'Jane Doe',
      PaidDateLabel: 'Paid Date',
      PaidDateVal: 'May 27, 2020',
      EOSLabel: 'EOS',
      RepricingDiscountLabel: 'Repricing Discount',
      RepricingDiscountVal: '$4800.00',
      IneligibleAmountLabel: 'Ineligible Amount',
      IneligibleAmountVal: '$0.00',
      NonSharableAmountLabel: 'Non-Sharable Amount',
      NonSharableAmountVal: '$1000.00',
      ConsultationFeeLabel: 'Consultation Fee',
      ConsultationFeeVal: '$300.00',
      SharingPercentageLabel: 'Sharing Percentage',
      SharingPercentageVal: '$320.00'
    }
  },
  {
    ExpenseNumberLabel: 'Expense Number',
    ExpenseNumberVal: '1259',
    DateOfServiceLabel: 'Date of Service',
    DateOfServiceVal: 'July 20, 2020',
    statusClass: 'Final',
    status: 'Final',
    ProviderLabel: 'Provider',
    ProviderVal: 'Apollo Hospital',
    ChargedLabel: 'Charged',
    ChargedVal: '$350.00',
    PaidLabel: 'Paid',
    PaidVal: '$1800.00',
    MoreDetail: {
      MemberLabel: 'Member',
      MemberVal: 'Marry Jim',
      PaidDateLabel: 'Paid Date',
      PaidDateVal: 'Feb 27, 2020',
      EOSLabel: 'EOS',
      RepricingDiscountLabel: 'Repricing Discount',
      RepricingDiscountVal: '$2500.00',
      IneligibleAmountLabel: 'Ineligible Amount',
      IneligibleAmountVal: '$20.00',
      NonSharableAmountLabel: 'Non-Sharable Amount',
      NonSharableAmountVal: '$1500.00',
      ConsultationFeeLabel: 'Consultation Fee',
      ConsultationFeeVal: '$350.00',
      SharingPercentageLabel: 'Sharing Percentage',
      SharingPercentageVal: '$420.00'
    }
  },
  {
    ExpenseNumberLabel: 'Expense Number',
    ExpenseNumberVal: '1248',
    DateOfServiceLabel: 'Date of Service',
    DateOfServiceVal: 'July 22, 2020',
    statusClass: 'InReview',
    status: 'In Review',
    ProviderLabel: 'Provider',
    ProviderVal: 'Healthway Clinic',
    ChargedLabel: 'Charged',
    ChargedVal: '$505.00',
    PaidLabel: 'Paid',
    PaidVal: 'NA',
    MoreDetail: {
      MemberLabel: 'Member',
      MemberVal: 'Jane Doe',
      PaidDateLabel: 'Paid Date',
      PaidDateVal: 'May 27, 2020',
      EOSLabel: 'EOS',
      RepricingDiscountLabel: 'Repricing Discount',
      RepricingDiscountVal: '$4800.00',
      IneligibleAmountLabel: 'Ineligible Amount',
      IneligibleAmountVal: '$0.00',
      NonSharableAmountLabel: 'Non-Sharable Amount',
      NonSharableAmountVal: '$1000.00',
      ConsultationFeeLabel: 'Consultation Fee',
      ConsultationFeeVal: '$300.00',
      SharingPercentageLabel: 'Sharing Percentage',
      SharingPercentageVal: '$320.00'
    }
  },
  {
    ExpenseNumberLabel: 'Expense Number',
    ExpenseNumberVal: '1259',
    DateOfServiceLabel: 'Date of Service',
    DateOfServiceVal: 'July 20, 2020',
    statusClass: 'Final',
    status: 'Final',
    ProviderLabel: 'Provider',
    ProviderVal: 'Apollo Hospital',
    ChargedLabel: 'Charged',
    ChargedVal: '$350.00',
    PaidLabel: 'Paid',
    PaidVal: '$1800.00',
    MoreDetail: {
      MemberLabel: 'Member',
      MemberVal: 'Marry Jim',
      PaidDateLabel: 'Paid Date',
      PaidDateVal: 'Feb 27, 2020',
      EOSLabel: 'EOS',
      RepricingDiscountLabel: 'Repricing Discount',
      RepricingDiscountVal: '$2500.00',
      IneligibleAmountLabel: 'Ineligible Amount',
      IneligibleAmountVal: '$20.00',
      NonSharableAmountLabel: 'Non-Sharable Amount',
      NonSharableAmountVal: '$1500.00',
      ConsultationFeeLabel: 'Consultation Fee',
      ConsultationFeeVal: '$350.00',
      SharingPercentageLabel: 'Sharing Percentage',
      SharingPercentageVal: '$420.00'
    }
  },
  {
    ExpenseNumberLabel: 'Expense Number',
    ExpenseNumberVal: '1248',
    DateOfServiceLabel: 'Date of Service',
    DateOfServiceVal: 'July 22, 2020',
    statusClass: 'InReview',
    status: 'In Review',
    ProviderLabel: 'Provider',
    ProviderVal: 'Healthway Clinic',
    ChargedLabel: 'Charged',
    ChargedVal: '$505.00',
    PaidLabel: 'Paid',
    PaidVal: 'NA',
    MoreDetail: {
      MemberLabel: 'Member',
      MemberVal: 'Jane Doe',
      PaidDateLabel: 'Paid Date',
      PaidDateVal: 'May 27, 2020',
      EOSLabel: 'EOS',
      RepricingDiscountLabel: 'Repricing Discount',
      RepricingDiscountVal: '$4800.00',
      IneligibleAmountLabel: 'Ineligible Amount',
      IneligibleAmountVal: '$0.00',
      NonSharableAmountLabel: 'Non-Sharable Amount',
      NonSharableAmountVal: '$1000.00',
      ConsultationFeeLabel: 'Consultation Fee',
      ConsultationFeeVal: '$300.00',
      SharingPercentageLabel: 'Sharing Percentage',
      SharingPercentageVal: '$320.00'
    }
  },
  {
    ExpenseNumberLabel: 'Expense Number',
    ExpenseNumberVal: '1259',
    DateOfServiceLabel: 'Date of Service',
    DateOfServiceVal: 'July 20, 2020',
    statusClass: 'Final',
    status: 'Final',
    ProviderLabel: 'Provider',
    ProviderVal: 'Apollo Hospital',
    ChargedLabel: 'Charged',
    ChargedVal: '$350.00',
    PaidLabel: 'Paid',
    PaidVal: '$1800.00',
    MoreDetail: {
      MemberLabel: 'Member',
      MemberVal: 'Marry Jim',
      PaidDateLabel: 'Paid Date',
      PaidDateVal: 'Feb 27, 2020',
      EOSLabel: 'EOS',
      RepricingDiscountLabel: 'Repricing Discount',
      RepricingDiscountVal: '$2500.00',
      IneligibleAmountLabel: 'Ineligible Amount',
      IneligibleAmountVal: '$20.00',
      NonSharableAmountLabel: 'Non-Sharable Amount',
      NonSharableAmountVal: '$1500.00',
      ConsultationFeeLabel: 'Consultation Fee',
      ConsultationFeeVal: '$350.00',
      SharingPercentageLabel: 'Sharing Percentage',
      SharingPercentageVal: '$420.00'
    }
  }
]
